import React, { useEffect, useState } from 'react';
import AboutBgImg from '../images/about-bg.png';
import TeamImg from '../images/team1.png';
import MainBannerImg from '../images/main-banner1.png';
import ChartIcon from '../images/icon1.png';
import SandGlass from '../images/icon3.png';
import GraphicPageIcon from '../images/icon2.png';
import CoverIcon from '../images/icon4.png';
import BookImg from '../images/book-about.png';

const About = () => {
  useEffect(() => {
    const timer = setTimeout(() => {
      document.querySelector('.loader-wrapper').style = 'display: none';
    }, 2000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  const [bgImg, setBgImg] = useState({
    backgroundImage: `url(${AboutBgImg})`,
  });

  useEffect(() => {
    setBgImg({
      backgroundImage: `url(${AboutBgImg})`,
    });
  }, []);

  return (
    <section id="about" className="about" style={bgImg}>
      <div className="about-decor">
        <div className="about-circle1">
          <img src={TeamImg} alt="team1" />
        </div>
        <div className="about-circle2">
          <img src={MainBannerImg} alt="banner1" />
        </div>
      </div>
      <div className="container">
        <div className="row ">
          <div className="col-md-5">
            <div className="about-contain">
              <div>
                <h2 className="title f-bold">O publikacji</h2>
                <p className="caption-about">
                  Główne założenie: przedstawić anatomię, pojęcie biomechaniki oraz samego
                  analizowania ruchu odnosząc się do sportu, treningu siłowego i to wszystko w
                  sposób jak najbardziej prosty i zrozumiały. Gotowe tipy i wskazówki oraz wiedza,
                  którą sam/-a zrozumiesz i wykorzystasz, aby ruszać się zdrowiej i bardziej
                  efektywnie.
                </p>
                <div className="row sm-mb">
                  <div className="col-6">
                    <ul className="about-style">
                      <li>
                        <div className="about-icon">
                          <div>
                            <img src={ChartIcon} alt="200-photos" />
                          </div>
                        </div>
                        <div className="about-text">
                          <h3>
                            Ponad 200 zdjęć
                            <br />i wykresów
                          </h3>
                        </div>
                      </li>
                      <li>
                        <div className="about-icon">
                          <div>
                            <img src={SandGlass} alt="300-links" />
                          </div>
                        </div>
                        <div className="about-text">
                          <h3>
                            Ponad 300 odnośników
                            <br />
                            do badań i artykułów
                            <br />
                            naukowych
                          </h3>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="col-6">
                    <ul className="about-style">
                      <li>
                        <div className="about-icon">
                          <div>
                            <img src={GraphicPageIcon} alt="oprawa-graficzna" />
                          </div>
                        </div>
                        <div className="about-text">
                          <h3>
                            Profesionalna
                            <br />
                            oprawa graficzna
                          </h3>
                        </div>
                      </li>
                      <li>
                        <div className="about-icon">
                          <div>
                            <img src={CoverIcon} alt="twarda-okladka" />
                          </div>
                        </div>
                        <div className="about-text">
                          <h3>
                            Twarda
                            <br />
                            okładka
                          </h3>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* <div className="top-margin"> */}
                {/*  <button */}
                {/*    type="button" */}
                {/*    className="btn btn-custom theme-color theme-color" */}
                {/*  > */}
                {/*    view more */}
                {/*  </button> */}
                {/* </div> */}
              </div>
            </div>
          </div>
          <div className="col-md-7 d-medium-none">
            <div className="about-right">
              <div className="about-phone">
                <img src={BookImg} className="img-fluid" alt="aboutus" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
