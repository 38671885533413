import React, { useEffect, useState } from 'react';
import 'react-modal-video/scss/modal-video.scss';
import Tilt from 'react-tilt';
import { Link } from 'react-scroll';
import Menu from './Navbar';
import About from './About';
import Feature from './Feature';
import ScreenShot from './Screenshot';
// import Team from "./Team";
// import Blog from "./Blog";
// import Price from "./Price";
import Contact from './Contact';
// import Subscribe from "./Subscribe";
import Footer from './Footer';
import Testimonial from './Testimonial';
// import ModalVideo from "react-modal-video";
import SliderImg from '../images/home3-slider.png';
// import PlayButton from "../images/home2/play-button.png";
import HomeBookImg from '../images/home-3-img.png';

const Home = () => {
  /* For changing background image by changing the color */
  const [bgImg, setBgImg] = useState({
    backgroundImage: `url(${SliderImg})`,
  });

  useEffect(() => {
    setBgImg({
      backgroundImage: `url(${SliderImg})`,
    });
    document.body.classList.remove('landing-page');
    document.body.classList.remove('home-style-two');
    document.body.classList.add('home-style');
    document.body.classList.add('three');
  }, []);

  /* For Open video modal */
  // const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      {/* Navbar Component */}
      <Menu homePage="home-three" />

      {/* Home Two Section Start */}
      <section id="home" className="home home-three vertical-scrolling" style={bgImg}>
        <div className="container">
          <div className="row">
            <div className="col-md-5 col-sm-12">
              <div className="mobile-banner-image">
                <Tilt options={{ perspective: 110, speed: 400, max: 1.2, scale: 1 }}>
                  <img src={HomeBookImg} className="img-fluid" alt="slider-caption" />
                </Tilt>
              </div>
              <div className="home-contain">
                <div className="text-white">
                  <div className="contain">
                    <h3 className="f-color">Książka dla sportowców i nie tylko...</h3>
                    <h1 className="home-head-text text-white">
                      <span className="f-color f-bold">Podstawy </span>
                      <span className="f-bold">biomechaniki ruchu</span>
                      <br />
                      <span className="f-bold f-color">człowieka</span>
                    </h1>
                    <Link to="about" spy smooth offset={-70} duration={500}>
                      <h3 className="h-link">&gt; Dowiedz się więcej</h3>
                    </Link>
                  </div>
                  {/* <div className="play-button"> */}
                  {/*  <ModalVideo */}
                  {/*    channel="vimeo" */}
                  {/*    isOpen={isOpen} */}
                  {/*    videoId="54298665" */}
                  {/*    height={600} */}
                  {/*    width={800} */}
                  {/*    onClose={() => setIsOpen(false)} */}
                  {/*  /> */}
                  {/*  <a href="javascript" onClick={(e)=>{e.preventDefault()}} className="popup-vimeo animated-circle"> */}
                  {/*    <img */}
                  {/*      src={PlayButton} */}
                  {/*      alt="play-button" */}
                  {/*      onClick={() => setIsOpen(true)} */}
                  {/*      className="img-fluid" */}
                  {/*    /> */}
                  {/*  </a> */}
                  {/* </div> */}
                </div>
              </div>
            </div>
            <div className="col-sm-7">
              <div className="home-right">
                <Tilt options={{ perspective: 110, speed: 400, max: 1.2, scale: 1 }}>
                  <img src={HomeBookImg} className="img-fluid" alt="slider-caption" />
                </Tilt>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Home Two Section End */}

      {/* About Component */}
      <About />

      {/* Feature Component */}
      <Feature />

      {/* ScreenShot Component */}
      <ScreenShot />

      {/* Team Component */}
      {/* <Team /> */}

      {/* Blog Component */}
      {/* <Blog /> */}

      {/* Price Component */}
      {/* <Price /> */}

      {/* Testimonial Component */}
      <Testimonial />

      {/* Contact Component */}
      <Contact />

      {/* Subscription Component */}
      {/* <Subscribe /> */}

      {/* Footer Component */}
      <Footer />
    </div>
  );
};

export default Home;
