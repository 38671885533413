import React, { useEffect, useState } from 'react';
import LocateImg from '../images/locate.png';
import TelephoneIconImg from '../images/telephone.png';
import RegulationsImg from '../images/regulations-27.png';
import EmailIconImg from '../images/fotter-email.png';
import FooterBgImg from '../images/footer-bg.png';
import Logo from '../images/logo-footer.png';
import InstIconImg from '../images/inst-27.png';
import FbIconImg from '../images/fb-icon.png';
import YtIconImg from '../images/yt-31.png';

const Footer = () => {
  useEffect(() => {
    const timer = setTimeout(() => {
      document.querySelector('.loader-wrapper').style = 'display: none';
    }, 2000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  const [bgImg] = useState({
    backgroundImage: `url(${FooterBgImg})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  });

  return (
    <div>
      <section id="footer" className="p-0" style={bgImg}>
        <div className="container-fluid">
          <div className="bottom-section">
            <div className="container">
              <div className="row">
                <div className="col-md-12 p-0">
                  <div className="address-bar">
                    <div className="logo-footer">
                      <img src={Logo} alt="logo" />
                    </div>

                    <div>
                      <ul className="footer-style">
                        <li>
                          <div className="footer-icon">
                            <img src={LocateImg} alt="locate" />
                          </div>
                          <div className="footer-address">
                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                            <a
                              href=""
                              onClick={(e) => {
                                e.preventDefault();
                              }}
                            >
                              ul. Złota 4, 74-400 Dębno,
                            </a>
                          </div>
                        </li>
                        <li>
                          <div className="footer-icon">
                            <img src={TelephoneIconImg} alt="telephone" />
                          </div>
                          <div className="footer-address">
                            <a href="tel:777777777">777 777 777</a>
                          </div>
                        </li>
                        <li>
                          <div className="footer-icon">
                            <img src={EmailIconImg} alt="footer-email" />
                          </div>
                          <div className="footer-address">
                            <a href="mailto:biuro@mechanikaruchu.pl">biuro@mechanikaruchu.pl</a>
                          </div>
                        </li>
                        <li className="social-icons-li">
                          <div className="footer-icon social-icons">
                            <a href="https://www.facebook.com/kdobropolski">
                              <img src={FbIconImg} alt="facebook-icon" />
                            </a>
                            <a href="https://www.instagram.com/krystian_dobropolski/">
                              <img src={InstIconImg} alt="instagram-icon" />
                            </a>
                            <a href="https://www.facebook.com/kdobropolski">
                              <img src={YtIconImg} alt="youtube-icon" />
                            </a>
                          </div>
                        </li>
                        <li>
                          <div className="footer-icon">
                            <a href={process.env.GATSBY_REGULATIONS_FILE_URL} target="_blank">
                              <img src={RegulationsImg} alt="footer-regulations" />
                            </a>
                          </div>
                          <div className="footer-address">
                            <a href={process.env.GATSBY_REGULATIONS_FILE_URL} target="_blank">
                              Regulamin
                            </a>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="copyright-section index-footer">
        <p>MECHANIKA RUCHU 2021 CREATED WITH LOVE</p>
      </div>

      <div className="tap-top">
        <div>
          <i className="fa fa-angle-double-up" />
        </div>
      </div>
      <div id="fb-root" />
      {/* Your customer chat code */}
      <div
        className="fb-customerchat"
        page_id="2123438804574660"
        theme_color="#18e7d3"
        logged_in_greeting="Hi! Welcome to PixelStrap Themes  How can we help you?"
        logged_out_greeting="Hi! Welcome to PixelStrap Themes  How can we help you?"
      />
    </div>
  );
};

export default Footer;
