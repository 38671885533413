import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import TestimonialImg from '../images/Testimonial.png';
import TestimonialBgImg from '../images/testimonial-bg.png';
import NextArrowImg from '../images/next.png';
import BackArrowImg from '../images/back.png';
import Avatar1 from '../images/A001.png';
import Avatar2 from '../images/A002.png';
import Testimonial1 from '../images/Testimonial1.png';
import Testimonial2 from '../images/Testimonial2.png';

const Testimonial = () => {
  useEffect(() => {
    const timer = setTimeout(() => {
      document.querySelector('.loader-wrapper').style = 'display: none';
    }, 2000);
    return () => {
      clearTimeout(timer);
    };
  }, []);
  const [avtarBg, setAvtarBg] = useState({
    backgroundImage: `url(${TestimonialImg})`,
  });

  const [bgImg, setBgImg] = useState({
    backgroundImage: `url(${TestimonialBgImg})`,
  });

  useEffect(() => {
    setBgImg({
      backgroundImage: `url(${TestimonialBgImg})`,
    });
    setAvtarBg({
      backgroundImage: `url(${TestimonialImg})`,
    });
  }, []);

  // eslint-disable-next-line react/prop-types
  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <button
      {...props}
      className={`slick-next slick-arrow${
        currentSlide === slideCount - 1 ? ' slick-disabled' : ''
      }`}
      aria-hidden="true"
      aria-disabled={currentSlide === slideCount - 1}
      type="button"
    >
      <img className="owl-next" src={NextArrowImg} alt="next-arrow" />
    </button>
  );

  // eslint-disable-next-line react/prop-types
  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <button
      {...props}
      className={`slick-prev slick-arrow${currentSlide === 0 ? ' slick-disabled' : ''}`}
      aria-hidden="true"
      aria-disabled={currentSlide === 0}
      type="button"
    >
      <img className="owl-prev" src={BackArrowImg} alt="back-arrow" />
    </button>
  );
  // Slick slider Option for Testimonial
  const options = {
    arrows: true,
    infinite: true,
    dots: true,
    appendDots: (dots) => (
      <span className=".blog-carousel.owl-theme .owl-dots .owl-dot.active span">{dots}</span>
    ),
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
    marginRight: 30,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 0,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          margin: 0,
        },
      },

      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };

  // Dynamic Testimonial Easy to Update
  const data = [
    {
      id: 1,
      name: 'Sebastian Kot',
      designation:
        'Mistrz Strength Wars, Instruktor trójboju siłowego i sportów siłowych, Team KFD',
      photo: Avatar1,
      description:
        'Książka zrobiła na mnie ogromne wrażenie. Krystian włożył w napisanie jej dużo pracy. Wiedzy jest naprawdę sporo,  została ona rzetelnie i precyzyjnie przedstawiona, z odnośnikami oraz prostymi grafikami, które zrozumie przeciętny Kowalski. Książka rozbudowana jest o działy, które od A do Z wprowadzają w zakres biomechaniki, między innymi jest to anatomia w zakresie niezbędnym aby zrozumieć dalszą część książki. Prócz suchych faktów, znalazło  się w niej miejsce na część praktyczną dla osób, które są miłośnikami dźwigania. Piśmiennictwo z tej dziedziny w Polsce jest dość ubogie, wiele osób szuka informacji na ten temat, ta książka uzupełnia nisze tej branży. Wszystko podane jak na tacy. Szczerze polecam tę pozycję. Praca solidna, szczegółowa, mała encyklopedia biomechaniki.',
    },
    {
      id: 2,
      name: 'dr n. o zdr. Magdalena Gębska',
      designation: 'Fizjoterapeuta stomatologiczny',
      photo: Avatar2,
      description:
        'Jako fizjoterapeuta i pracownik naukowo-dydaktyczny, dotychczas nie spotkałam podobnego opracowania w języku polskim, gdzie w sposób rzeczowy, przejrzysty i wyczerpujący zostały opisane zagadnienia dotyczące biomechaniki ruchu człowieka. Przygotowanie podręcznika dla specjalistów z zakresu wychowania fizycznego, kultury fizycznej (trenerów personalnych), fizjoterapii, a także lekarzy i przedstawicieli innych grup zawodowych, jest z pewnością sporym wyzwaniem, któremu moim zdaniem Autor sprostał z łatwością.',
    },
  ];

  // Dynamic Testimonial Data Loop
  const DataList = data.map((item) => (
    <div className="testimonial-item" key={item.id}>
      <div className="testimonial-block">
        <div className="testimonial-avtar" style={avtarBg}>
          <img src={item.photo} alt="" />
        </div>
        <div className="testimonial-text">
          <p>{item.description}</p>
          <h3>{item.name}</h3>
          <h6>{item.designation}</h6>
        </div>
      </div>
    </div>
  ));

  return (
    <section id="testimonial" className="testimonial" style={bgImg}>
      <div className="testimonial-decor">
        <div className="testi-circle1">
          <img src={Testimonial2} alt="" />
        </div>
        <div className="testi-circle2">
          <img src={Testimonial1} alt="" />
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-10 offset-lg-1">
            <Slider {...options} className="testimonial-carousel">
              {DataList}
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonial;
