import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ContactBgImg from '../images/Contact.png';
import mainBannerImg from '../images/main-banner12.png';
import mainBannerImg2 from '../images/main-banner1.png';
import HomeBookImg from '../images/home-3-img.png';

const Contact = () => {
  useEffect(() => {
    const timer = setTimeout(() => {
      document.querySelector('.loader-wrapper').style = 'display: none';
    }, 2000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  const [bgImg, setBgImg] = useState({
    backgroundImage: `url(${ContactBgImg})`,
  });
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [street, setStreet] = useState('');
  const [streetNumber, setStreetNumber] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [city, setCity] = useState('');
  const [country, setCountry] = useState('');
  const [message, setMessage] = useState('');
  const [quantity, setQuantity] = useState('');
  const [payment, setPayment] = useState('Przelew bankowy');
  const [agreementMarketing, setAgreementMarketing] = useState('');
  const [agreementRegulations, setAgreementRegulations] = useState('');

  const [error, setError] = useState(false);
  const [send, setMessageSend] = useState(false);

  useEffect(() => {
    setBgImg({
      backgroundImage: `url(${ContactBgImg})`,
    });
  }, []);

  const onChangeName = (event) => {
    setName(event.target.value);
  };

  const onChangeStreet = (event) => {
    setStreet(event.target.value);
  };

  const onChangeStreetNumber = (event) => {
    setStreetNumber(event.target.value);
  };

  const onChangeZipCode = (event) => {
    setZipCode(event.target.value);
  };

  const onChangeCity = (event) => {
    setCity(event.target.value);
  };

  const onChangeCountry = (event) => {
    setCountry(event.target.value);
  };

  const onChangePhone = (event) => {
    setPhone(event.target.value);
  };

  const onChangeEmail = (event) => {
    setEmail(event.target.value);
  };

  const onChangeMessage = (event) => {
    setMessage(event.target.value);
  };

  const onChangeQuantity = (event) => {
    setQuantity(event.target.value);
  };

  const onChangePayment = (event) => {
    setPayment(event.target.value);
  };

  const onChangeAgreementMarketing = (event) => {
    setAgreementMarketing(event.target.checked);
  };

  const onChangeAgreementRegulations = (event) => {
    setAgreementRegulations(event.target.checked);
  };

  const resetForm = () => {
    setName('');
    setEmail('');
    setPhone('');
    setMessage('');
    setError(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append('name', name);
    data.append('phone', phone);
    data.append('email', email);
    data.append('street', street);
    data.append('message', message);
    data.append('streetNumber', streetNumber);
    data.append('city', city);
    data.append('zipCode', zipCode);
    data.append('country', country);
    data.append('quantity', quantity);
    data.append('payment', payment);
    data.append('agreementMarketing', agreementMarketing);
    data.append('agreementRegulations', agreementRegulations);

    axios({
      method: 'POST',
      url: `${process.env.GATSBY_API_URL}/form/send.php`,
      data,
    }).then((response) => {
      if (response.data.status === true) {
        resetForm();
        setError(false);
      } else if (response.data.status === false) {
        setError(true);
      }
      setMessageSend(true);
    });
  };

  return (
    <section id="contact" className="contact" style={bgImg}>
      <div className="contact-decor">
        <div className="contact-circle1">
          <img src={mainBannerImg} alt="" />
        </div>
        <div className="contact-circle2">
          <img src={mainBannerImg2} alt="" />
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-lg-8 col-sm-12">
            <div className="contact-us">
              <div className="w-100">
                <h2 className="title f-bold">Napisz do mnie i zamów</h2>
                <form
                  className="theme-form"
                  onSubmit={handleSubmit}
                  method="POST"
                  encType="application/x-www-form-urlencoded"
                >
                  <div className="form-group">
                    <input
                      name="name"
                      onChange={onChangeName}
                      value={name}
                      id="name"
                      type="text"
                      className="form-control"
                      placeholder="Imię i Nazwisko"
                      required="required"
                    />
                  </div>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-sm-12 col-md-6 md-fgrup-margin">
                        <input
                          name="street"
                          onChange={onChangeStreet}
                          value={street}
                          id="street"
                          type="text"
                          className="form-control"
                          placeholder="Ulica"
                          required="required"
                        />
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <input
                          onChange={onChangeStreetNumber}
                          value={streetNumber}
                          type="text"
                          name="streetNumber"
                          id="streetNumber"
                          className="form-control"
                          placeholder="Nr domu/mieszkania"
                          required="required"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-sm-12 col-md-6 md-fgrup-margin">
                        <input
                          name="zipcode"
                          onChange={onChangeZipCode}
                          value={zipCode}
                          id="zipcode"
                          type="text"
                          className="form-control"
                          placeholder="Kod pocztowy"
                          required="required"
                        />
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <input
                          onChange={onChangeCity}
                          value={city}
                          type="city"
                          name="city"
                          id="city"
                          className="form-control"
                          placeholder="Miasto"
                          required="required"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-sm-12 col-md-6 md-fgrup-margin">
                        <input
                          name="country"
                          onChange={onChangeCountry}
                          value={country}
                          id="country"
                          type="text"
                          className="form-control"
                          placeholder="Kraj"
                          required="required"
                        />
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <input
                          onChange={onChangePhone}
                          value={phone}
                          type="number"
                          name="phone"
                          id="phone"
                          className="form-control"
                          placeholder="Telefon"
                          required="required"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <input
                      onChange={onChangeEmail}
                      value={email}
                      type="email"
                      name="email"
                      id="email"
                      className="form-control"
                      placeholder="Adres e-mail"
                      required="required"
                    />
                  </div>
                  <div className="form-group">
                    <textarea
                      onChange={onChangeMessage}
                      value={message}
                      className="form-control"
                      id="message"
                      name="message"
                      rows="4"
                      placeholder="Dodatkowe uwagi"
                    />
                  </div>
                  <div className="form-group">
                    <input
                      onChange={onChangeQuantity}
                      value={quantity}
                      type="number"
                      name="quantity"
                      id="quantity"
                      className="form-control"
                      placeholder="Ilość sztuk książki"
                      required="required"
                    />
                  </div>
                  <div className="form-group">
                    <h3 className="mb-3">Płatność ( do wyboru):</h3>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="payment"
                        id="payment1"
                        value="Przelew bankowy"
                        onChange={onChangePayment}
                        checked={payment === 'Przelew bankowy'}
                      />
                      <label className="form-check-label" htmlFor="payment1">
                        Przelew bankowy
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="payment"
                        id="payment2"
                        value="Pobranie"
                        onChange={onChangePayment}
                        checked={payment === 'Pobranie'}
                      />
                      <label className="form-check-label" htmlFor="payment2">
                        Pobranie
                      </label>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="agreementMarketing"
                        name="agreementMarketing"
                        checked={agreementMarketing}
                        onChange={onChangeAgreementMarketing}
                        required
                      />
                      <label className="form-check-label agreement" htmlFor="agreementMarketing">
                        Wyrażam zgodę na przetwarzanie moich danych osobowych w celu zrealizowania
                        zamówienia. Dane osobowe wykorzystane będą tylko w celu zrealizowania
                        wysyłki.
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="agreementRegulations"
                        name="agreementRegulations"
                        checked={agreementRegulations}
                        onChange={onChangeAgreementRegulations}
                        required
                      />
                      <label className="form-check-label agreement" htmlFor="agreementRegulations">
                        Przeczytałem/-am{' '}
                        <a
                          className="btn-link"
                          href={process.env.GATSBY_REGULATIONS_FILE_URL}
                          target="_blank"
                        >
                          regulamin
                        </a>{' '}
                        i go akceptuję.
                      </label>
                    </div>
                  </div>
                  <div className="form-button">
                    <button type="submit" className="btn btn-custom theme-color">
                      Wyślij
                    </button>
                  </div>
                  {send && (
                    <div>
                      {error ? (
                        <span className="failure-send">
                          Wystąpił problem z wysłaniem wiadomości
                        </span>
                      ) : (
                        <span className="success-send">Wiadomość została wysłana</span>
                      )}
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>
          <div className="col-lg-4 d-medium-none-lg">
            <div className="contact-right">
              <img src={HomeBookImg} className="img-fluid" alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
